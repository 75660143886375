import { useAuth } from 'oidc-react';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

function Redirect() {

        return <h2>Authenticating...</h2>

}

export default Redirect;