import { AuthProvider } from 'oidc-react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Redirect from './Redirect';
import { navigateToUrl } from 'single-spa';

function AuthRouter({ paths }) {

    const oidcConfig = {

        onSignIn: (userData) => {
            sessionStorage.setItem("access_token", userData.access_token);
            sessionStorage.setItem("userData", userData);
            navigateToUrl('/home');
        },

        authority: 'https://authenticate.qa.bnymellon.net/',
        clientId: 'u-08nfFAbU9Rj4vxxHXvr8w6t2bwvEqDQH2vUqKzZOI',
        clientSecret: 'LaFFyl1zcrY6Wb4q6G7u7F4DUQ5Hbqc_4ngGuWv1_jQ',
        redirectUri: 'https://arena.qa.bnymellon.com/'
    };

    const routes = paths.map((route) => <Route key={route.pathName} path={route.path} element={<div />}></Route>);

    return (
        <div>
            <AuthProvider {...oidcConfig}>
                <Router>
                    <Routes>
                        {routes}
                    </Routes>
                </Router>
            </AuthProvider>

            <Router>
                <Routes>
                    <Route key="auth-redirect" path="/login/redirect" element={<Redirect />}></Route>
                </Routes>
            </Router>
        </div>

    )
}

export default AuthRouter;