import { Route } from "react-router-dom";
import AuthRouter from "./components/AuthRouter";


function App(props) {

    return (
        <div>
            <AuthRouter paths={ props.paths }></AuthRouter>
        </div>
    )
}

export default App;